import {Feature} from 'geojson';
import {ServiceMeta} from '../../http/service-meta';
import {ServiceList} from '../../http/service-list';
import {ServiceResponse} from '../../http/service-response';
import {Service} from '../../http/service';
import {Environment} from '../../../environments/environment';
import {Session} from '../../session';

/**
 * Geolocation service is used to get information related to maps and locations.
 */
export class GeolocationService {
	/**
	 * Search for locations in the map using MapBox API.
	 *
	 * Will retrieve a list of locations based on a search query.
	 *
	 * @param search - Search query, might be based on name of places, street addresses
	 * @param resultLimit - Max number of results to retrieve.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns - The list of locations matching the search.
	 */
	public static async searchPlaces(search: string, resultLimit: number = 10, hideLoading: boolean = false, displayError: boolean = true): Promise<Feature[]> {
		if (search.length === 0) {
			return [];
		}

		const meta: ServiceMeta = structuredClone(ServiceList.mapbox.places);
		meta.url = meta.url + '/' + encodeURI(search) + '.json';

		const request: ServiceResponse = await Service.fetch(meta, {
			// eslint-disable-next-line
			access_token: Environment.MAPBOX_TOKEN,
			limit: resultLimit
		}, null, null, Session.session, hideLoading, displayError);


		return request.response.features;
	}
}
