<div class="dashboard-main-page-container">
	<div class="dashboard-display-type-container">
		<uno-button [icon]="'./assets/dashboards/pin-icon.svg'" [height]="'35px'" [color]="this.displayMode() === dashboardMainPageDisplayMode.PINNED ? 'special-blue' : 'none'" (click)="this.setDisplay(dashboardMainPageDisplayMode.PINNED)">{{'fixedDashboards' | translate}}</uno-button>
		<uno-button [icon]="'./assets/dashboards/list-icon.svg'" [height]="'35px'" [color]="this.displayMode() === dashboardMainPageDisplayMode.LIST ? 'special-blue' : 'none'" (click)="this.setDisplay(dashboardMainPageDisplayMode.LIST)">{{'dashboardsList' | translate}}</uno-button>
	</div>
	@if (this.displayMode() === dashboardMainPageDisplayMode.PINNED) {
		<div class="dashboard-main-page-dashboard-tabs">
			<uno-tab>
				@for (dashboard of this.pinnedDashboards; track dashboard; let i = $index) {
					@if(dashboard.widgets.length !== 0) {
						<uno-tab-section [title]="dashboard.name" [active]="i === 0" [borders]="false">
							<uno-dashboard [dashboard]="dashboard" [editable]="false"></uno-dashboard>
						</uno-tab-section>
					}
				}
			</uno-tab>
		</div>
	} @else if (this.displayMode() === dashboardMainPageDisplayMode.LIST) {
		<div class="dashboard-main-page-list-container">
			<div class="dashboard-main-page-filter-container">
				<div style="display: flex; justify-content: space-between; flex-direction: row; gap: 12px">
					<!-- Create Dashboard Button -->
					<uno-button [icon]="'assets/icons/uno/add.svg'" [iconColor]="'var(--light)'" [height]="'35px'" [color]="'primary'" (click)="this.createDashboard()">{{'createDashboard' | translate}}</uno-button>

					<!-- Search bar -->
					<uno-searchbar style="width: 100%;" [value]="selfStatic.filters.search" (valueChange)="onSearchChange($event)"></uno-searchbar>
				</div>

				<!-- Filters -->
				<div class="dashboard-main-page-filter-bar">
					<uno-filter-bar [filters]="selfStatic.filters" [options]="selfStatic.filterOptions" (onChange)="this.onFilterChange($event);"></uno-filter-bar>
				</div>
			</div>
			<div style="width: 100%; height: calc(100% - 205px); overflow: hidden;">
				<uno-responsive-table-list [loadMore]="this.loadTableItems" [totalItems]="this.tableTotalItemsCount" [layout]="this.tableLayout" [pageSize]="selfStatic.tablePageSize" [labelShown]="true" [selectable]="false" (rowClick)="app.navigator.navigate('/menu/dash-boards/edit', {uuid: $event.element.uuid, editable: false});" (sortChange)="this.sortChanged($event.sortBy)">
				</uno-responsive-table-list>
			</div>
		</div>
	}
</div>
