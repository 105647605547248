<uno-tab [dragDisabled]="false">
	<uno-tab-section [title]="'Buttons'">
		<p>UNO Button</p>
		<div style="display: flex; flex-direction: row;overflow-y: auto;">
			@for (size of this.buttonSize; track size) {
				<div style="display: flex; flex-direction: column; margin-left:10px; justify-content: space-around;">
					<span style="display: flex;align-items: center;justify-content: center;">{{size | uppercase}}</span>
					@for (color of this.buttonColor; track color) {
						<div>
							<uno-button icon="assets/icons/uno/add.svg" type="fill" size="{{size}}" color="{{color}}">Button</uno-button>
						</div>
					}
					<uno-button icon="assets/icons/uno/add.svg" [disabled]="true" type="fill" size="{{size}}">Button</uno-button>
					@for (color of this.buttonColor; track color) {
						<div>
							<uno-button icon="assets/icons/uno/add.svg" type="border" size="{{size}}" color="{{color}}">Button</uno-button>
						</div>
					}
				</div>
			}
		</div>
	</uno-tab-section>

	<uno-tab-section [title]="'No Data'">
		<p>UNO No Data</p>
		<uno-no-data></uno-no-data>
	</uno-tab-section>

	<uno-tab-section [title]="'Filters'">
		<p>UNO Filter</p>
		<uno-filter-bar [filters]="filters" [options]="filterOptions"></uno-filter-bar>
	</uno-tab-section>

	<uno-tab-section [title]="'Inputs'">
		<p>UNO Input</p>
		<uno-input placeholder="Text Input" type="text"></uno-input>
		<br />
		<uno-input disabled="true" placeholder="Disabled Input"></uno-input>
		<br />
		<uno-input placeholder="Password Input" type="password"></uno-input>
		<br />
		<uno-input placeholder="Number Input" type="number"></uno-input>
		<br />
		<uno-input placeholder="Email Input" type="email"></uno-input>
		<br />

		<p>UNO Switch</p>
		<uno-switch></uno-switch>
		<br />
		<uno-switch disabled="true"></uno-switch>
	</uno-tab-section>

	<uno-tab-section [title]="'Text'">
		<p>UUID</p>
		<uno-uuid [uuid]="this.uuid"></uno-uuid>

		<p>UNO Text</p>
		<div style="width: 300px; height: 100px; border: 1px dashed var(--gray-5);">
			<uno-text alignment="right" verticalAlignment="top" multiline="true">Top Right Corner</uno-text>
		</div>
		<div style="width: 300px; height: 100px; border: 1px dashed var(--gray-5);">
			<uno-text alignment="left" verticalAlignment="bottom" multiline="true">Bottom Left Corner</uno-text>
		</div>

		<div style="width: 300px; height: 100px; border: 1px dashed var(--gray-5);">
			<uno-text alignment="center" overflow="clip" verticalAlignment="bottom">Clip {{this.text}}</uno-text>
		</div>
		<div style="width: 300px; height: 100px; border: 1px dashed var(--gray-5);">
			<uno-text overflow="ellipsis">Ellipsis {{this.text}}</uno-text>
		</div>
		<div style="width: 300px; height: 100px; border: 1px dashed var(--gray-5);">
			<uno-text overflow="ellipsis" verticalAlignment="center">Ellipsis Center {{this.text}}</uno-text>
		</div>
		<div style="width: 300px; height: 100px; border: 1px dashed var(--gray-5);">
			<uno-text overflow="clip" [multiline]="true">Clip Multiline {{this.text}}</uno-text>
		</div>
		<div style="width: 300px; height: 100px; border: 1px dashed var(--gray-5);">
			<uno-text overflow="clip" alignment="center" [multiline]="true">Clip Multiline Center {{this.text}}
			</uno-text>
		</div>
		<div style="width: 300px; height: 100px; border: 1px dashed var(--gray-5);">
			<uno-text overflow="ellipsis" [multiline]="true">Ellipsis Multiline {{this.text}}</uno-text>
		</div>

		<p>UNO Tooltip</p>
		<div style="width: 50px; height: 50px; border: 1px dashed var(--gray-5);" uno-tooltip uno-tooltip-direction="top" uno-tooltip-text="Top">Top</div>
		<br />
		<div style="width: 50px; height: 50px; border: 1px dashed var(--gray-5);" uno-tooltip uno-tooltip-direction="bottom" uno-tooltip-text="Bottom">Bottom</div>
		<br />
		<div style="width: 50px; height: 50px; border: 1px dashed var(--gray-5);" uno-tooltip uno-tooltip-direction="right" uno-tooltip-text="Right">Right</div>
		<br />
		<div style="width: 50px; height: 50px; border: 1px dashed var(--gray-5);" uno-tooltip uno-tooltip-direction="left" uno-tooltip-text="Left">Left</div>
	</uno-tab-section>

	<uno-tab-section [title]="'Options'">
		<p>UNO Options List</p>
		<uno-options-list [options]="this.options" (valueChange)="this.componentChanges($event)"></uno-options-list>

		<p>UNO Options Search</p>
		<uno-options-search [options]="this.options" (sendResult)="this.componentChanges($event)"></uno-options-search>

		<p>UNO Searchbar Dropdown</p>
		<uno-searchbar-dropdown [debounce]="100" [retrieveOptions]="searchBarRetrieveOptions"></uno-searchbar-dropdown>

		<p>UNO Periodicity Input Selector</p>
		<uno-periodicity-selector [min]="23" [max]="1"></uno-periodicity-selector>

		<p>UNO Lateral Selector</p>
		<uno-lateral-selector-switch [options]="this.smallOptions" (valueChange)="this.componentChanges($event)"></uno-lateral-selector-switch>
	</uno-tab-section>

	<uno-tab-section [title]="'Badges'">
		<p>UNO Badge</p>
		<uno-badge [value]="100000"></uno-badge>
		<br />
		<uno-badge [max]="10" [value]="100000"></uno-badge>
		<br />
		<uno-badge [max]="10" [value]="100000" disabled="true"></uno-badge>
		<br />
		<uno-badge [value]="10"></uno-badge>
	</uno-tab-section>

	<uno-tab-section [title]="'Tree'">
		<p>UNO Tree</p>
		<div style="width: 100%; height: calc(100% - 100px); padding: 0px 10px; overflow: hidden;">
			<uno-tree [loadData]="this.treeLoadItems" [layout]="this.treeLayout" [onClick]="this.treeOnClick"></uno-tree>
		</div>
	</uno-tab-section>

	<uno-tab-section [title]="'Table'">
		<p>UNO Table</p>
		<div style="width: 100%; height: calc(100% - 100px); overflow: hidden;">
			<uno-table [loadMore]="this.loadTableItems" [totalItems]="selfStatic.tableTotalItemsCount" [layout]="this.tableLayout" [pageSize]="selfStatic.tablePageSize" [rowClickable]="true" (rowClick)="this.app.openInTab('/menu', {})" (rowChecked)="this.checkedRow($event)">
			</uno-table>
		</div>
		<div style="width: 80px; height: 34px;">
			<uno-button type="fill" size="medium" color="primary" (click)="this.table.export()">Export</uno-button>
		</div>
	</uno-tab-section>

	<uno-tab-section [title]="'Mixed'">
		<p>UNO Mixed Table/List</p>
		<div style="width: 100%; height: calc(100% - 100px); overflow: hidden;">
			<uno-responsive-table-list [loadMore]="this.loadTableItems" [totalItems]="selfStatic.tableTotalItemsCount" [layout]="this.tableLayout" [pageSize]="selfStatic.tablePageSize" [labelShown]='true' [rowClickable]="true" (rowClick)="this.app.openInTab('/menu', {})" (rowChecked)="this.checkedRow($event)" (sortChange)="this.componentChanges($event)">
			</uno-responsive-table-list>
		</div>
		<div style="width: 80px; height: 34px;">
			<uno-button type="fill" size="medium" color="primary" (click)="this.responsiveTableList.export()">Export</uno-button>
		</div>
	</uno-tab-section>
	<uno-tab-section [title]="'Dashboard'" [active]="true">
		<uno-dashboard [dashboard]="this.dashboard" [editable]="false"></uno-dashboard>
	</uno-tab-section>
</uno-tab>
