import {WidgetTypes} from 'src/app/models/dashboards/widget-types';
import {UserPermissions} from 'src/app/models/users/user-permissions';
import {DashboardWidget} from 'src/app/models/dashboards/widget';
import {CSSUtils} from 'src/app/utils/css-utils';
import {AtexInspectionService} from 'src/app/modules/atex/inspections/services/atex-inspection.service';
import {AtexInspectionResult, AtexInspectionResultLabel} from 'src/app/models/atex-inspections/inspections/atex-inspection-result';
import {Locale} from 'src/app/locale/locale';
import {Modal} from 'src/app/modal';
import {WidgetViewTypes} from 'src/app/models/dashboards/widget-view-types';
import {BaseLayout} from '../../widget-layout';
import {ChartData} from '../../components/widget/widget.component';

export class WidgetAtexInspectionResult {
	public displayType = WidgetTypes.DASHBOARD_WIDGET_TYPE_ATEX_INSPECTION_RESULTS;

	public static visualization = [WidgetViewTypes.DOUGHNUT_CHART];

	public permissions = [UserPermissions.ATEX_INSPECTION];

	public static async getData(widgetData: DashboardWidget): Promise<ChartData> {
		// Count number of inspections of each type
		const request = await AtexInspectionService.countStatusResult(true, false);
		const result = request.inspections.result;

		const chartData = {
			labels: [Locale.get(AtexInspectionResultLabel.get(AtexInspectionResult.APPROVED)), Locale.get(AtexInspectionResultLabel.get(AtexInspectionResult.CONDITIONALLY_APPROVED)), Locale.get(AtexInspectionResultLabel.get(AtexInspectionResult.FAILED)), Locale.get(AtexInspectionResultLabel.get(AtexInspectionResult.NONE))],
			datasets: [
				{
					label: '',
					data: [result.approved, result.conditionallyApproved, result.failed, result.none],
					backgroundColor: [
						CSSUtils.getVariable('--success-normal'),
						CSSUtils.getVariable('--warning-normal'),
						CSSUtils.getVariable('--error-normal'),
						CSSUtils.getVariable('--brand-primary')
					],
					borderColor: [
						CSSUtils.getVariable('--success-normal'),
						CSSUtils.getVariable('--warning-normal'),
						CSSUtils.getVariable('--error-normal'),
						CSSUtils.getVariable('--brand-primary')
					],
					cutout: '70%'
				}
			]
		};

		return chartData;
	};

	public static async openEditModal(widgetData: DashboardWidget): Promise<DashboardWidget> {
		await Modal.form(Locale.get('edit'), widgetData, BaseLayout);

		return widgetData;
	}
}
