/**
 * Possible Atex zone levels.
 */
export const AtexZones = {
	UNCLASSIFIED: -1,
	ZONE_0: 0,
	ZONE_1: 1,
	ZONE_2: 2,
	ZONE_20: 20,
	ZONE_21: 21,
	ZONE_22: 22,
	MINE: 30
};

export const AtexZonesLabel: Map<number, string> = new Map([
	[AtexZones.UNCLASSIFIED, 'unclassifiedZone'],
	[AtexZones.ZONE_0, 'zone0'],
	[AtexZones.ZONE_1, 'zone1'],
	[AtexZones.ZONE_2, 'zone2'],
	[AtexZones.ZONE_20, 'zone20'],
	[AtexZones.ZONE_21, 'zone21'],
	[AtexZones.ZONE_22, 'zone22'],
	[AtexZones.MINE, 'mine']
]);

/**
 * Possible values for the Atex group.
 */
export const AtexGroup = {
	UNKNOWN: -1,
	I: 1, // Mines
	II: 2 // Surface
};
export const AtexGroupKeys = Object.keys(AtexGroup);
export const AtexGroupLabels = AtexGroupKeys.reduce(function(map, value) {
	map.set(AtexGroup[value], AtexGroup[value] === AtexGroup.UNKNOWN ? '-' : value);
	return map;
}, new Map());

/**
 * Possible values for the Atex category.
 */
export const AtexCategory = {
	UNKNOWN: -1,
	C1: 1, // Zones 0, 1, 2, 20, 21, 22, M1
	C2: 2, // Zones 1, 2, 21, 22, M2
	C3: 3 // Zones 2, 22
};
export const AtexCategoryKeys = Object.keys(AtexCategory);
export const AtexCategoryLabels = AtexCategoryKeys.reduce(function(map, value) {
	map.set(AtexCategory[value], AtexCategory[value] === AtexCategory.UNKNOWN ? '-' : String(AtexCategory[value]));
	return map;
}, new Map());

/**
 * Possible values for the EPL (protection level).
 */
export const AtexEpl = {
	UNKNOWN: -1,
	Ga: 0,
	Gb: 1,
	Gc: 2,
	Da: 20,
	Db: 21,
	Dc: 22,
	Ma: 30,
	Mb: 31
};
export const AtexEplKeys = Object.keys(AtexEpl);
export const AtexEplLabels = AtexEplKeys.reduce(function(map, value) {
	map.set(AtexEpl[value], AtexEpl[value] === AtexEpl.UNKNOWN ? '-' : value);
	return map;
}, new Map());

/**
 * List of possible Atex protections.
 */
export const AtexProtections = {
	e: 0,
	d: 1,
	nA: 2,
	nC: 3,
	q: 4,
	ia: 5,
	ib: 6,
	ic: 7,
	nL: 8,
	p: 9,
	nR: 10,
	nZ: 11,
	ma: 12,
	mb: 13,
	o: 14,
	tD: 15,
	iaD: 16,
	ibD: 17,
	pD: 18,
	maD: 19,
	mbD: 20,
	fr: 21,
	c: 22,
	b: 23,
	k: 24,
	g: 25,
	ta: 26,
	tb: 27,
	tc: 28,
	mc: 29,
	s: 30,
	op_Is: 31, // eslint-disable-line
	op_Pr: 32, // eslint-disable-line
	op_Sh: 33, // eslint-disable-line
	h: 34,
	icD: 35
};

export const AtexProtectionsKeys = Object.keys(AtexProtections);
export const AtexProtectionsLabels = AtexProtectionsKeys.reduce(function(map, value) {
	map.set(AtexProtections[value], value.replace('_', ' '));
	return map;
}, new Map());

/**
 * Atex explosion groups.
 */
export const AtexExplosionGroups = {
	UNKNOWN: -1,
	IIA: 0,
	IIB: 1,
	IIC: 2,
	IIIA: 3,
	IIIB: 4,
	IIIC: 5,
	I: 6,
	II: 7,
	IIB_H2: 8
};
export const AtexExplosionGroupsKeys = Object.keys(AtexExplosionGroups);
export const AtexExplosionGroupsLabels = AtexExplosionGroupsKeys.reduce(function(map, value) {
	map.set(AtexExplosionGroups[value], AtexExplosionGroups[value] === AtexExplosionGroups.UNKNOWN ? '-' : value.replace('_', ' + '));
	return map;
}, new Map());

/**
 * Default temperature values for Gas environment.
 *
 * For Dust environment the temperature field is open.
 */
export const AtexTemperature = {
	T1: 450,
	T2: 300,
	T3: 200,
	T4: 135,
	T5: 100,
	T6: 85
};
export const AtexTemperatureKeys = Object.keys(AtexTemperature);
export const AtexTemperatureLabels = AtexTemperatureKeys.reduce(function(map, value) {
	map.set(AtexTemperature[value], value);
	return map;
}, new Map());

/**
 * Atex ambient (Gas, Dust, Oils Mines).
 */
export const AtexAmbient = {
	UNKNOWN: -1,
	D: 0, // Dust
	G: 1, // Gas
	M: 2, // Mines
	O: 3 // Oils & Liquids
};
export const AtexAmbientKeys = Object.keys(AtexAmbient);
export const AtexAmbientLabels = AtexAmbientKeys.reduce(function(map, value) {
	map.set(AtexAmbient[value], AtexAmbient[value] === AtexAmbient.UNKNOWN ? '-' : value);
	return map;
}, new Map());

/**
 * IP rating dust protection level.
 */
export const IPRatingDust = {
	UNKNOWN: -1,
	IP0X: 0,
	IP1X: 10,
	IP2X: 20,
	IP3X: 30,
	IP4X: 40,
	IP5X: 50,
	IP6X: 60
};
export const IPRatingDustKeys = Object.keys(IPRatingDust);
export const IPRatingDustLabels = Object.keys(IPRatingDust).reduce(function(map, value) {
	return map.set(IPRatingDust[value], IPRatingDust[value] === IPRatingDust.UNKNOWN ? 'X' : `IP${String(Math.round(IPRatingDust[value] / 10))}`);
}, new Map());


/**
 * IP rating water protection rate.
 */
export const IPRatingWater = {
	UNKNOWN: -1,
	IPX0: 0,
	IPX1: 1,
	IPX2: 2,
	IPX3: 3,
	IPX4: 4,
	IPX5: 5,
	IPX6: 6,
	IPX7: 7,
	IPX8: 8
};
export const IPRatingWaterKeys = Object.keys(IPRatingWater);
export const IPRatingWaterLabels = Object.keys(IPRatingWater).reduce(function(map, value) {
	map.set(IPRatingWater[value], IPRatingWater[value] === IPRatingWater.UNKNOWN ? 'X' : String(IPRatingWater[value]));
	return map;
}, new Map());
