import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {ServiceResponse} from '../../../http/service-response';
import {UnoFormField} from '../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../components/uno-forms/uno-form/uno-form-field-types';
import {Geolocation} from '../../../models/geolocation';
import {UUID} from '../../../models/uuid';
import {Locale} from '../../../locale/locale';
import {Modal} from '../../../modal';
import {ProgressBar} from '../../../progress-bar';
import {Session} from '../../../session';
import {FileUtils} from '../../../utils/file-utils';
import {XlsxUtils} from '../../../utils/xlsx-utils';
import {AssetService} from '../services/asset.service';
import {Atex} from '../../../models/atex/atex';
import {AtexExplosionGroups, AtexTemperature, AtexZones} from '../../../models/atex/atex-enums';
import {APAsset} from '../../../models/asset-portfolio/asset';
import {APAssetSubType} from '../../../models/asset-portfolio/asset-sub-type';
import {APAssetType} from '../../../models/asset-portfolio/asset-type';
import {AssetTypeService} from '../services/asset-type.service';
import {InspectionFormUtils} from '../../inspections/data/form/inspection-form-utils';
import {AssetTypeSelectorField} from '../../inspections/data/field/inspection-form-fields';

// Type to be used as return type on the method that processes rows data
type XLSXRowResult = {
	// Indicates if there was created a new asset or updated an existent one
	createdNew: boolean;

	// Custom error message
	errorMessage?: string;

	// Error object to be add some more error info (from API)
	error?: Error;
};

export class APAssetImport {
	/**
	 * Import assets from XLSX file.
	 */
	public static async importAssetsXLSX(): Promise<void> {
		// List of attributes and possible names in the XLSX file
		const attributes: {attribute: string, names: string[]}[] = [
			{
				attribute: 'uuid',
				names: ['id', 'uuid', 'identificador único', 'unique identifier', 'unique id', 'asset uuid'].concat(Locale.getAllTranslations('id')).concat(Locale.getAllTranslations('uuid'))
			},
			{
				attribute: 'name',
				names: ['name'].concat(Locale.getAllTranslations('name'))
			},
			{
				attribute: 'tag',
				names: ['tag', 'tagnum'].concat(Locale.getAllTranslations('tag'))
			},
			{
				attribute: 'typeUuid',
				names: Locale.getAllTranslations('typeUuid')
			},
			{
				attribute: 'type',
				names: ['type'].concat(Locale.getAllTranslations('type'))
			},
			{
				attribute: 'subtypeUuid',
				names: Locale.getAllTranslations('subtypeUuid')
			},
			{
				attribute: 'subtype',
				names: ['subtype'].concat(Locale.getAllTranslations('subtype'))
			},
			{
				attribute: 'parentUuid',
				names: Locale.getAllTranslations('parentUuid')
			},
			{
				attribute: 'parentTag',
				names: Locale.getAllTranslations('parentTag')
			},
			{
				attribute: 'description',
				names: ['descricao', 'comentario', 'description', 'comment', 'descrição'].concat(Locale.getAllTranslations('description'))
			},
			{
				attribute: 'latitude',
				names: ['latitude'].concat(Locale.getAllTranslations('latitude'))
			},
			{
				attribute: 'longitude',
				names: ['longitude'].concat(Locale.getAllTranslations('longitude'))
			},
			{
				attribute: 'altitude',
				names: ['altitude'].concat(Locale.getAllTranslations('altitude'))
			},
			{
				attribute: 'qr',
				names: ['qr'].concat(Locale.getAllTranslations('qr'))
			},
			{
				attribute: 'nfc',
				names: ['nfc'].concat(Locale.getAllTranslations('nfc'))
			},
			{
				attribute: 'manufacturer',
				names: ['marca', 'manufacturer', 'fabricante'].concat(Locale.getAllTranslations('manufacturer'))
			},
			{
				attribute: 'manufacturingYear',
				names: ['manufacturingYear'].concat(Locale.getAllTranslations('manufacturingYear'))
			},
			{
				attribute: 'model',
				names: ['modelo', 'model'].concat(Locale.getAllTranslations('model'))
			},
			{
				attribute: 'serialNumber',
				names: ['serial', 'numero serie', 'serial number'].concat(Locale.getAllTranslations('serialNumber'))
			},
			{
				attribute: 'installationDate',
				names: ['installationDate'].concat(Locale.getAllTranslations('installationDate'))
			},
			{
				attribute: 'atex',
				names: ['atex'].concat(Locale.getAllTranslations('atex'))
			},
			{
				attribute: 'atexZone',
				names: ['classificação área atex', 'classificao zona atex', 'atex zone classification', 'atex zone'].concat(Locale.getAllTranslations('zoneClassification'))
			},
			{
				attribute: 'atexZoneTemperature',
				names: ['temperatura área atex', 'temperatura zona atex', 'atex zone temperature'].concat(Locale.getAllTranslations('temperatureClass'))
			},
			{
				attribute: 'atexZoneExplosion',
				names: ['explosividade área atex', 'grupo de exploso zona atex', 'grupo de explosão zona atex', 'atex zone explosion group'].concat(Locale.getAllTranslations('explosionGroup'))
			}
		];

		const config = {createAtexInspection: 'none', defaultAssetTypeUuid: null};

		const layout: UnoFormField[] = [
			InspectionFormUtils.cloneField(AssetTypeSelectorField, {
				attribute: 'defaultAssetTypeUuid',
				label: 'defaultAssetType'
			}),
			{
				label: 'createAtexInspection',
				attribute: 'createAtexInspection',
				sort: false,
				type: UnoFormFieldTypes.OPTIONS,
				options: [
					{label: Locale.get('none'), value: 'none'},
					{label: Locale.get('onlyForNew'), value: 'onlyForNew'},
					{label: Locale.get('forAll'), value: 'forAll'}
				]
			}
		];

		await Modal.form(Locale.get('import'), config, layout);

		let assetRows: any[] = await XlsxUtils.chooseFileXLSX();

		const stats = {
			// The number of assets that failed on import
			failed: 0,
			// The number of assets that were successfully created on import
			created: 0,
			// The number of assets that were successfully updated on import
			updated: 0
		};

		const progress = new ProgressBar();
		progress.show();
		progress.update(Locale.get('loadingAssetsData'), 0);
		
		// Gets an asset either by its UUID, tag qr or nfc.
		// 
		// @param uuid - The UUID to search the asset by.
		// @param tag - The tag to search the asset by.
		// @param qr - The qr to search the asset by.
		// @param nfc - The nfc to search the asset by.
		// @returns The found asset or null if not found.
		const getAsset = async function(uuid?: UUID, tag?: string, qr?: string, nfc?: string): Promise<APAsset> {
			if (!uuid && !tag && !qr && !nfc) {
				return null;
			}

			// If UUID is present get asset by UUID
			try {
				if (uuid) {
					return await AssetService.get(uuid, true, false);
				} else if (tag) {
					// If neither of the previous attributes are present, check existent asset by its Tag
					return await AssetService.getByTag(tag, true, false);
				} else if (qr) {
					// If neither of the previous attributes are present, check existent asset by its QR
					return await AssetService.getByQR(qr, true, false);
				} else if (nfc) {
					// If neither of the previous attributes are present, check existent asset by its NFC
					return await AssetService.getByNFC(nfc, true, false);
				} else {
					throw new Error('No indentifier found.');
				}
			} catch {
				return null;
			}
		};

		// Creates or updates an already existent asset with data from xlsx document.
		//
		// @param data - The row with data to be read in order to create/update asset info.
		// @returns a promise with a boolean telling if there was a creation of a new asset and an error message upon save failure.
		const processAssetRow = async function(row: Map<string, any>): Promise<XLSXRowResult> {
			let createNew: boolean = false;

			let asset: APAsset = await getAsset(row.get('uuid'), row.get('tag'), row.get('qr'), row.get('nfc'));

			// Create new asset if no valid asset was returned
			if (asset === null) {
				createNew = true;
				asset = new APAsset();
				asset.atex = new Atex();
			}

			if (XlsxUtils.hasCell(row, 'uuid')) {
				asset.uuid = String(row.get('uuid'));
			}
			if (XlsxUtils.hasCell(row, 'name')) {
				asset.name = String(row.get('name'));
			}
			if (XlsxUtils.hasCell(row, 'tag')) {
				asset.tag = String(row.get('tag'));
			}

			// Asset type
			let assetType: APAssetType;
			try {
				if (XlsxUtils.hasCell(row, 'typeUuid')) {
					// Get asset type by uuid
					
					assetType = await AssetTypeService.getType(row.get('typeUuid'), true);
				} else if (XlsxUtils.hasCell(row, 'type')) {
					// Get asset type by name
					const typeRequest = await Service.fetch(ServiceList.assetPortfolio.assetType.getByName, null, null, {name: row.get('type')}, Session.session, true, false);
					assetType = APAssetType.parse(typeRequest.response.type);
				}
			} catch (e) {
				return {createdNew: false, errorMessage: Locale.get('errorImportAssetsInvalidType'), error: new Error(e.response)};
			}

			// On create
			if (!asset.typeUuid) {
				if (assetType) {
					asset.typeUuid = assetType.uuid;
				} else {
					if (config.defaultAssetTypeUuid) {
						asset.typeUuid = config.defaultAssetTypeUuid;
					} else {
						return {createdNew: false, errorMessage: Locale.get('errorImportAssetsMissingDefaultType')};
					}
				}
			// On update
			} else {
				// If type provided on xls use it.
				if (assetType) {
					asset.typeUuid = assetType.uuid;
				} else if (config.defaultAssetTypeUuid) {
					// If no type is provided on xls, use default if set
					asset.typeUuid = config.defaultAssetTypeUuid;
				}
			}

			// Asset sub-type
			let subTypeRequest: ServiceResponse;
			if (!assetType && !config.defaultAssetTypeUuid && XlsxUtils.hasCell(row, 'subtypeUuid')) {
				return {createdNew: false, errorMessage: Locale.get('errorImportAssetsProvideType')};
			}
			if (assetType || config.defaultAssetTypeUuid) {
				try {
					if (XlsxUtils.hasCell(row, 'subtypeUuid')) {
						// Get asset sub-type by uuid
						subTypeRequest = await Service.fetch(ServiceList.assetPortfolio.assetSubType.get, null, null, {uuid: row.get('subtypeUuid')}, Session.session, true, false);
					} else if (XlsxUtils.hasCell(row, 'subtype')) {
						// Get asset sub-type by name
						subTypeRequest = await Service.fetch(ServiceList.assetPortfolio.assetSubType.getByName, null, null, {name: row.get('subtype')}, Session.session, true, false);
					}
				} catch (e) {
					return {createdNew: false, errorMessage: Locale.get('errorImportAssetsInvalidSubType'), error: new Error(e.response)};
				}

				if (subTypeRequest) {
					const assetSubType: APAssetSubType = APAssetSubType.parse(subTypeRequest.response.subType);

					if (assetSubType.typeUuid !== asset.typeUuid) {
						return {createdNew: false, errorMessage: Locale.get('errorImportAssetsInvalidSubtypeType')};
					}

					asset.subTypeUuid = assetSubType.uuid;
				}
			}

			// Parent Asset
			if (XlsxUtils.hasCell(row, 'parentUuid')) {
				try {
					const parentAsset: APAsset = await getAsset(row.get('parentUuid'));
					if (parentAsset) {
						asset.parentUuid = parentAsset.uuid;
					} else {
						return {createdNew: false, errorMessage: 'Invalid/Nonexistent parent asset UUID.'};
					}
				} catch (e) {
					return {createdNew: false, errorMessage: 'Invalid/Nonexistent parent asset UUID.', error: e};
				}
			} else if (XlsxUtils.hasCell(row, 'parentTag')) {
				try {
					const parentAsset: APAsset = await getAsset(null, row.get('parentTag'));
					if (parentAsset) {
						asset.parentUuid = parentAsset.uuid;
					} else {
						return {createdNew: false, errorMessage: 'Invalid/Nonexistent parent asset tag.'};
					}
				} catch (e) {
					return {createdNew: false, errorMessage: 'Invalid/Nonexistent parent asset tag.', error: e};
				}
			}

			if (XlsxUtils.hasCell(row, 'description')) {
				asset.description = String(row.get('description'));
			}
			if (XlsxUtils.hasCell(row, 'qr')) {
				asset.qr = String(row.get('qr'));
			}
			if (XlsxUtils.hasCell(row, 'nfc')) {
				asset.nfc = String(row.get('nfc'));
			}
			if (XlsxUtils.hasCell(row, 'manufacturer')) {
				asset.manufacturer = String(row.get('manufacturer'));
			}
			if (XlsxUtils.hasCell(row, 'manufacturingYear')) {
				asset.manufacturingYear = Number(row.get('manufacturingYear'));
			}
			if (XlsxUtils.hasCell(row, 'model')) {
				asset.model = String(row.get('model'));
			}
			if (XlsxUtils.hasCell(row, 'serialNumber')) {
				asset.serialNumber = String(row.get('serialNumber'));
			}
			if (XlsxUtils.hasCell(row, 'installationDate')) {
				asset.installationDate = new Date(row.get('installationDate'));
			}

			// Geolocation
			if (XlsxUtils.hasCell(row, 'latitude') && XlsxUtils.hasCell(row, 'longitude')) {
				const latitude: number = Number.parseFloat(row.get('latitude'));
				const longitude: number = Number.parseFloat(row.get('longitude'));

				if (isNaN(latitude) || isNaN(longitude)) {
					return {createdNew: false, errorMessage: 'Latitude or longitude have invalid values.', error: null};
				}

				if (!asset.position) {
					asset.position = new Geolocation(latitude, longitude);
				} else {
					asset.position.latitude = latitude;
					asset.position.longitude = longitude;
				}
			} else if (XlsxUtils.hasCell(row, 'latitude') || XlsxUtils.hasCell(row, 'longitude')) {
				return {createdNew: false, errorMessage: 'Both latitude and longitude required to set geolocation value.', error: null};
			}

			if (XlsxUtils.hasCell(row, 'altitude')) {
				const altitude: number = Number.parseFloat(row.get('altitude'));

				if (isNaN(altitude)) {
					return {createdNew: false, errorMessage: 'Altitude has invalid value.', error: null};
				}

				if (asset.position) {
					asset.position.altitude = altitude;
				} else {
					return {createdNew: false, errorMessage: 'Altitude value present without valid latitude/longitude values.', error: null};
				}
			}

			if (asset.position && !Geolocation.isValid(asset.position)) {
				return {createdNew: false, errorMessage: 'Invalid latitude/longitude/altitude values.', error: null};
			}

			if (XlsxUtils.hasCell(row, 'atex')) {
				asset.atex = Atex.parse(String(row.get('atex')));
			}

			// Atex Zone
			if (XlsxUtils.hasCell(row, 'atexZone')) {
				const zone: string = row.get('atexZone');

				// List of possible Atex zones and matching text
				const zoneList = [
					{
						text: '22',
						zone: AtexZones.ZONE_22
					},
					{
						text: '21',
						zone: AtexZones.ZONE_21
					},
					{
						text: '1',
						zone: AtexZones.ZONE_1
					},
					{
						text: '0',
						zone: AtexZones.ZONE_0
					},
					{
						text: '2',
						zone: AtexZones.ZONE_2
					},
					{
						text: 'M',
						zone: AtexZones.MINE
					}
				];

				for (let i = 0; i < zoneList.length; i++) {
					// Check if the Atex zone is still not present before adding
					if (zone.endsWith(zoneList[i].text) && asset.atex.zone.indexOf(zoneList[i].zone) === -1) {
						asset.atex.zone.push(zoneList[i].zone);
					}
				}
			}

			// Atex Temperature
			if (XlsxUtils.hasCell(row, 'atexZoneTemperature')) {
				const temperature = row.get('atexZoneTemperature');
				if (temperature === 'T1') {
					asset.atex.zoneTemperature = AtexTemperature.T1;
				} else if (temperature === 'T2') {
					asset.atex.zoneTemperature = AtexTemperature.T2;
				} else if (temperature === 'T3') {
					asset.atex.zoneTemperature = AtexTemperature.T3;
				} else if (temperature === 'T4') {
					asset.atex.zoneTemperature = AtexTemperature.T4;
				} else if (temperature === 'T5') {
					asset.atex.zoneTemperature = AtexTemperature.T5;
				} else if (temperature === 'T6') {
					asset.atex.zoneTemperature = AtexTemperature.T6;
				}

				if (typeof temperature === 'number') {
					asset.atex.zoneTemperature = temperature;
				}
			}

			// Atex Explosion Group
			if (XlsxUtils.hasCell(row, 'atexZoneExplosion')) {
				const explosion: string = row.get('atexZoneExplosion');
				if (explosion === 'IIA') {
					asset.atex.zoneExplosion = AtexExplosionGroups.IIA;
				} else if (explosion === 'IIB') {
					asset.atex.zoneExplosion = AtexExplosionGroups.IIB;
				} else if (explosion === 'IIC') {
					asset.atex.zoneExplosion = AtexExplosionGroups.IIC;
				} else if (explosion === 'IIIA') {
					asset.atex.zoneExplosion = AtexExplosionGroups.IIIA;
				} else if (explosion === 'IIIB') {
					asset.atex.zoneExplosion = AtexExplosionGroups.IIIB;
				} else if (explosion === 'IIIC') {
					asset.atex.zoneExplosion = AtexExplosionGroups.IIIC;
				} else if (explosion === 'I') {
					asset.atex.zoneExplosion = AtexExplosionGroups.I;
				}
			}

			// Create/update the asset data in the API
			if (createNew) {
				try {
					const req = await Service.fetch(ServiceList.assetPortfolio.asset.create, null, null, asset, Session.session, true, false);
					asset.uuid = req.response.uuid;
				} catch (e) {
					return {createdNew: false, errorMessage: Locale.get('errorImportAssetCreateFailed'), error: new Error(e.response)};
				}
			} else {
				try {
					await Service.fetch(ServiceList.assetPortfolio.asset.update, null, null, asset, Session.session, true, false);
				} catch (e) {
					return {createdNew: false, errorMessage: Locale.get('errorImportAssetUpdateFailed'), error: new Error(e.response)};
				}
			}

			// Create new Atex inspection entry if set
			if (config.createAtexInspection === 'forAll' || config.createAtexInspection === 'onlyForNew' && createNew) {
				try {
					await Service.fetch(ServiceList.atex.inspection.createForAsset, null, null, {assetUuid: asset.uuid}, Session.session);
				} catch (e) {
					return {createdNew: false, errorMessage: Locale.get('errorImportAssetsAtexCreationFailed'), error: new Error(e.response)};
				}
			}

			return {createdNew: createNew};
		};

		// Total number of rows in the original file
		const totalRows: number = assetRows.length;

		// Store the row index for each asset row
		for (const idx in assetRows) {
			// Document row starts from 1 but header must be excluded from count
			assetRows[idx]['Row'] = Number(idx) + 2; 
		}

		// List of asset rows that failed and which import should be retried
		let failedAssetRows: any[] = [];

		// List of errors of the last attempt
		let failedAssetErrors: XLSXRowResult[] = [];
		
		// How many assets have failed in the last attempt to import.
		let lastFailedCount: number = 0;
		
		// Try to import assets until its not possible to reduce the error count
		while (true) {
			// Iterate trough all rows that were still not imported.
			for (let i = 0; i < assetRows.length; i++) {
				// Remove the created asset row data from the list
				const assetRow = assetRows[i];
	
				// Update progress
				progress.update(Locale.get('importingData', {
					total: totalRows,
					imported: stats.created + stats.updated,
					failed: lastFailedCount
				}), (stats.created + stats.updated) / totalRows);
	
				// Read row data into map object
				const row: Map<string, any> = XlsxUtils.readRowAsMap(assetRow, attributes);
				
				const save: XLSXRowResult = await processAssetRow(row);
	
				if (save.errorMessage || save.error) {
					Object.assign(save, assetRow);

					
					// Build error message content
					let errorMessage: string = '';
					if (save.error && save.error.message && save.error.message.length > 0) {
						errorMessage = save.error.message;
					} 

					if (save.errorMessage && save.errorMessage.length > 0) {
						errorMessage = errorMessage.length > 0 ? errorMessage + ' (' + save.errorMessage + ')' : save.errorMessage;
					}

					// Add error message to the object
					assetRow['Error'] = errorMessage;

					failedAssetErrors.push(assetRow);
					failedAssetRows.push(assetRow);

					stats.failed++;
				} else {
					if (save.createdNew) {
						stats.created++;
					} else {
						stats.updated++;
					}
				}
			}
			
			if (failedAssetRows.length === lastFailedCount) {
				break;
			}

			// Assign the list of failed assets to retry import on next iteration
			assetRows = failedAssetRows;

			lastFailedCount = failedAssetRows.length;
			failedAssetErrors = [];
			failedAssetRows = [];
		}

		if (lastFailedCount) {
			XlsxUtils.writeFileObject(failedAssetErrors, 'failed_assets.xlsx');
		}

		progress.destroy();
		Modal.alert(Locale.get('success'), Locale.get('importedSuccessfullyCounters', {imported: stats.created, updated: stats.updated, failed: lastFailedCount}));
	}

	/**
	 * Export empty assets template as XLSx
	 */
	public static exportAssetsTemplateXlsx(): void {
		FileUtils.download('assets/template/assets.xlsx');
	}
}
