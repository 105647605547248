<uno-content [borders]="false">
	<div class="dashboard-edit-page-container">
		@if (this.editable() && !this.preview()) {
			<!-- The Widgets that are able to be used -->
			<div class="dashboard-edit-page-sidebar">
				<div class="dashboard-edit-page-sidebar-widget-container">
					@for (widget of widgets; track $index) {
						@if (widget.widgetType && widget.label) {
							<div class="dashboard-edit-page-sidebar-widget" (click)="this.addWidget(widget.widgetType, widget.distribution)">
								<span class="dashboard-edit-page-sidebar-widget-label uno-p2" title="{{widget.label}}">{{widget.label}}</span>
								<uno-icon [src]="'assets/components/global-calendar/add-icon.svg'"></uno-icon>
							</div>
						}
					}
				</div>
			</div>
		}
		<!-- Container for the dashboard that is being edited/created -->
		<div [ngStyle]="{width: this.editable() && !this.preview() ? 'calc(100% - 250px)' : '100%'}" class="dashboard-edit-page-dashboard-container" [ngStyle]="{position: this.editable() && !this.preview() ? 'relative' : 'unset', width: this.editable() && !this.preview() ? 'calc(100% - 300px)' : '100%', 'min-width': app.device.isDesktop() ? '410px': 'calc(100% - 120px)'}">
			@if (this.dashboard) {
				<div class="dashboard-edit-page-header">
					<div class="dashboard-edit-page-options-container">
						<div style="display: flex; gap: 4px; align-items: center;" [ngStyle]="{'max-width': this.editable() ? (app.device.isDesktop() ? 'calc(100% - 300px)' : 'calc(100% - 170px)') : '100%'}">
							<span class="uno-h7-bold dashboard-edit-page-name" title="{{this.dashboard.name}}">{{this.dashboard.name}}</span>
						</div>
						@if (this.editable()) {
							<div class="dashboard-edit-page-buttons" [ngStyle]="{'width': app.device.isDesktop() ? '296px' : '170px'}">
								<uno-button [height]="'28px'" [icon]="'assets/icons/assets/edit-icon.svg'" [iconColor]="'var(--brand-primary)'" [color]="buttonColor.PRIMARY" [type]="buttonType.BORDER" (click)="this.editDashboard()">{{app.device.isDesktop() ? ('edit' | translate) : ''}}</uno-button>
								<uno-button [height]="'28px'" [icon]="'assets/icons/uno/bin.svg'" [iconColor]="'var(--brand-primary)'" [color]="buttonColor.PRIMARY" [type]="buttonType.BORDER" (click)="this.deleteDashboard()">{{app.device.isDesktop() ? ('delete' | translate) : ''}}</uno-button>
								<uno-button [height]="'28px'" [icon]="'assets/icons/uno/visible.svg'" [color]="buttonColor.PRIMARY" [type]="buttonType.FILL" (click)="this.preview.set(!this.preview())">{{app.device.isDesktop() ? ('preview' | translate) : ''}}</uno-button>
							</div>
						}
					</div>
				</div>
				<div class="dashboard-edit-page-dashboard">
					<uno-dashboard [(dashboard)]="this.dashboard" [editable]="this.editable()"></uno-dashboard>
				</div>
			} @else {
				<uno-no-data></uno-no-data>
			}
		</div>
	</div>
</uno-content>
