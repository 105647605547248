import {InspectionData} from 'src/app/models/inspections/inspection/inspection-data';
import {InspectionReportTemplate} from 'src/app/models/inspections/project/inspection-report-template';
import {UUID} from '../../../models/uuid';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {Session} from '../../../session';
import {Inspection} from '../../../models/inspections/inspection/inspection';

export type InspectionServiceListDetailedParams = {
	// Where to start fetching items
	from?: number,
	// How many items to fetch
	count?: number,
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[]
	// Sort Direction to apply
	sortDirection?: string,
	// Sort field to sort by
	sortField?: string,
	// Data to apply
	fetchData?: boolean,
	// Project of the inspection
	project?: UUID
};

export type InspectionServiceListParams = {
	// Where to start fetching items
	from?: number,
	// How many items to fetch
	count?: number,
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[]
	// Sort Direction to apply
	sortDirection?: string,
	// Sort field to sort by
	sortField?: string,
	// Step array to filter by
	step?: UUID[]
};

export type InspectionServiceCountParams = {
	// Project UUID
	projectUuid?: UUID,
	// Step UUID
	stepUuid?: UUID,
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[]
};

export type InspectionServiceListDataParams = {
	// Where to start fetching items
	from?: number,
	// How many items to fetch
	count?: number,
	// Sort Direction to apply
	sortDirection?: string,
	// Sort field to sort by
	sortField?: string,
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[],
	// Step to filter by
	step?: UUID,
	// Project to filter by
	project?: UUID
};

export type InspectionServiceListDataResponse = {
	inspections: {
		// Inspection UUID
		uuid: UUID,
		// Inspection updated at
		updatedAt: Date,
		// Inspection name
		name: string | null,
		// Inspection description
		description: string,
		// Project information
		project: {
			// Project UUID
			uuid: UUID,
			// Project name
			name: string | null
		},
		// Asset information
		asset: {
			// Asset UUID
			uuid: UUID | null,
			// Asset name
			name: string | null,
			// Asset tag
			tag: string | null,
			// Asset pictures
			pictures: {
				// Picture UUID
				uuid: UUID,
				// Picture URL
				url: string
			}[]
		},
		// Inspection data
		inspectionData: {
			// Inspection data UUID
			uuid: UUID | null,
			// Step UUID
			stepUuid: UUID | null,
			// Inspection data
			data: any
		}[]
	}[],
	hasMore: boolean
};

export type InpectionServiceListAssetProjectsInspectionsResponse = {
	// Project information
	project: {
		// Project UUID
		uuid: UUID,
		// Project updated at
		updatedAt: Date,
		// Project name
		name: string | null
	},
	// Inspection associated with the project
	inspections: {
		// Inspection UUID
		uuid: UUID,
		// Inspection last update date
		updatedAt: Date,
		// Inspection name
		name: string | null
	}[]
};

export type InspectionServiceListDetailedResponse = {
	// Array of inspections
	inspections: Inspection[],
	// If there are more items to fetch
	hasMore: boolean
};

export type InspectionServiceListResponse =	{
	// If there are more items to fetch
	hasMore: boolean,
	// Request ID
	id: number,
	// Array of inspections
	inspections: {
		// UUID of the inspection
		uuid: UUID,
		// Datetime inspection was updated
		updatedAt: Date,
		// Name of the inspection
		name: string | null,
		// Description of the inspection
		description: string,
		// Project
		project: {
			// UUID of the project related with the inspection
			uuid: UUID,
			// Name of the project
			name: string | null,
			// The default report template of the project of this inspection
			defaultReportTemplate: InspectionReportTemplate | null
		},
		// Step structure
		step: {
			// Name of the step
			name: string
		},
		// Asset structure
		asset: {
			// Name of the asset
			name: string | null,
			// Tag of the asset
			tag: string | null,
			// Picture of the asset
			pictures: {
				// Picture UUID
				uuid: UUID,
				// Picture URL
				url: string
			}[]
		}
	}[]
};

export class InspectionService {
	/**
	 * Delete a inspection.
	 *
	 * @param uuid - UUID of the inspection.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 */
	public static async delete(uuid: UUID, hideLoading: boolean = false, displayError: boolean = true): Promise<void> {
		await Service.fetch(ServiceList.inspection.delete, null, null, {uuid: uuid}, Session.session, hideLoading, displayError);
	}

	/**
	 * Load the inspection data from the API by UUID.
	 *
	 * @param uuid - The UUID of the inspection to load.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns Inspection loaded from the API.
	 */
	public static async get(uuid: UUID, hideLoading: boolean = false, displayError: boolean = true): Promise<Inspection> {
		const request = await Service.fetch(ServiceList.inspection.get, null, null, {uuid: uuid}, Session.session, hideLoading, displayError);
		return Inspection.parse(request.response.inspection);
	}

	/**
	 * Get inspection by QR code.
	 *
	 * @param qr - QR code to search for.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns Inspection loaded from the API.
	 */
	public static async getByQR(qr: string, hideLoading: boolean = false, displayError: boolean = true): Promise<Inspection> {
		const request = await Service.fetch(ServiceList.inspection.getQR, null, null, {qr: qr}, Session.session, hideLoading, displayError);
		return Inspection.parse(request.response.inspection);
	}

	/**
	 * List inspections with all data associated.
	 *
	 * @param params - Parameters to filter the list.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The inspections in the database that match the params.
	 */
	public static async listDetailed(params: InspectionServiceListDetailedParams = {}, hideLoading: boolean = false, displayError: boolean = true): Promise<InspectionServiceListDetailedResponse> {
		const request = await Service.fetch(ServiceList.inspection.listDetailed, null, null, params, Session.session, hideLoading, displayError);

		const inspections: Inspection[] = request.response.inspections.map(function(i: any) {
			return Inspection.parse(i);
		});

		return {
			inspections: inspections,
			hasMore: request.response.hasMore
		};
	}


	/**
	 * List inspection with inspection response field data.
	 * 
	 * @param params - Filtering parameters.
	 */
	public static async listData(params: InspectionServiceListDataParams): Promise<InspectionServiceListDataResponse> {
		const request = await Service.fetch(ServiceList.inspection.listData, null, null, params, Session.session);
		return request.response;
	}

	/**
	 * List all inspections that match the params.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The inspections in the database that match the params.
	 */
	public static async list(params: InspectionServiceListParams = {}, hideLoading: boolean = false, displayError: boolean = true): Promise<InspectionServiceListResponse> {
		const request = await Service.fetch(ServiceList.inspection.list, null, null, params, Session.session, hideLoading, displayError);

		return {
			hasMore: request.response.hasMore,
			id: request.id,
			inspections: request.response.inspections
		};
	}

	/**
	 * List all inspections for an asset organized by their project.
	 *
	 * @param assetUuid - The UUID of the asset to list the inspections for.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The inspections in the database of the given asset UUID organized by project.
	 */
	public static async listAssetProjectsInspections(assetUuid: UUID, hideLoading: boolean = false, displayError: boolean = true): Promise<InpectionServiceListAssetProjectsInspectionsResponse[]> {
		const request = await Service.fetch(ServiceList.inspection.listByAssetAndProject, null, null, {assetUuid: assetUuid}, Session.session, hideLoading, displayError);
		return request.response.projectsInspections;
	}

	/**
	 * Count all inspections that match the params.
	 *
	 * @param params - The parameters to apply on the fetch request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The number of inspections in the database that match the params.
	 */
	public static async count(params: InspectionServiceCountParams = {}, hideLoading: boolean = false, displayError: boolean = true): Promise<number> {
		const request = await Service.fetch(ServiceList.inspection.count, null, null, params, Session.session, hideLoading, displayError);

		return request.response.count;
	}

	/**
	 * Load the inspection data from the API by UUID.
	 *
	 * @param uuid - The UUID of the inspection to load.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns Inspection data loaded from the API.
	 */
	public static async getInspectionData(uuid: UUID, hideLoading: boolean = false, displayError: boolean = true): Promise<InspectionData> {
		const request = await Service.fetch(ServiceList.inspection.inspectionData.get, null, null, {uuid: uuid}, Session.session, hideLoading, displayError);
		return InspectionData.parse(request.response.data);
	}

	/**
	 * Update an inspection with received data.
	 *
	 * @param inspection - The inspection to update.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 */
	public static async update(inspection: Inspection, hideLoading: boolean = false, displayError: boolean = true): Promise<void> {
		await Service.fetch(ServiceList.inspection.update, null, null, inspection, Session.session, hideLoading, displayError);
	}

	/**
	 * List inspections from gap UUIDs.
	 *
	 * @param gapUuids - The gap uuids to filter by on the api request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * 
	 * @returns Array of dynamic inspections that match the Gap UUIDS.
     */
	public static async listByGapUuids(gapUuids: UUID[], hideLoading: boolean = false, displayError: boolean = true): Promise<Inspection[]> {
		const request = await Service.fetch(ServiceList.inspection.listByGaps, null, null, {gapUuids: gapUuids}, Session.session, hideLoading, displayError);
		return request.response.inspections.map((d: any) => {
			return Inspection.parse(d);
		});
	}
}
