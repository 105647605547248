<div style="width:100%; height:100%;">
	<uno-tab>
		<uno-tab-section [active]="true" title="{{'subtype' | translate}}">
			<uno-form [layout]="this.assetSubTypeLayout" [object]="this.subType"></uno-form>

			<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
				<!-- Add Tab -->
				<uno-button (click)="this.addTab();">{{'addTab' | translate}}</uno-button>
			</div>

			<!-- Submit buttons -->
			<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
				<!-- Save / Create -->
				<uno-button (click)="this.update();">{{(this.createMode ? 'create' : 'save') | translate}}</uno-button>

				<!-- Update / Delete -->
				@if (!this.createMode) {
					@if ([userPermissions.ASSET_PORTFOLIO_ASSET_TYPE_EDIT] | hasPermissions) {
						<uno-button (click)="this.update(true);">{{'update' | translate}}</uno-button>
					}
					@if ([userPermissions.ASSET_PORTFOLIO_ASSET_TYPE_DELETE] | hasPermissions) {
						<uno-button (click)="this.delete();" color="error">{{'delete' | translate}}</uno-button>
					}
				}
			</div>

		</uno-tab-section>

		<!-- Dynamic asset form tabs -->
		@for (tab of this.tabs; track tab) {
			<uno-tab-section title="{{tab.name | translate}}">
				<uno-title>{{'tab' | translate}}</uno-title>
				<!-- FormTab form -->
				<uno-form [editable]="this.canEditTab(tab, this.subType.uuid)" [layout]="this.formTabLayout" [object]="tab"></uno-form>
				<!-- FormTabCards layout -->
				@for (card of tab.cards; track card.uuid; let j = $index) {
					<uno-title>{{'card' | translate}} {{j+1}}</uno-title>
					<uno-form [editable]="this.canEditTabCard(card)" [layout]="this.formTabCardLayout" [object]="card"></uno-form>
					<!-- FormBlockFields layout -->
					@if (card.block) {
						@for (field of card.block.fields; track field.uuid; let k = $index) {
							<uno-title>{{'card' | translate}} {{j+1}} - {{'field' | translate}} {{k+1}}</uno-title>
							<uno-form [editable]="this.canEditFormBlock(card)" [layout]="this.formBlockFieldLayout" [object]="field"></uno-form>
							<!-- FormBlockFields sorting buttons -->
							@if (this.canEditFormBlock(card)) {
								<ion-item class="ion-text-end ion-margin-horizontal ion-no-padding" lines="none" style="height: 40px; margin-bottom: 8px; --inner-padding-end: 0px;">
									<ion-row slot="end">
										<ion-button mode="ios" (click)="this.moveFormBlockFieldUp(card, field)" [ngStyle]="{'visibility': k < card.block.fields.length - 1 ? 'visible' : 'hidden'}" expand="block" fill="outline" style="margin-left: 8px;">
											<ion-icon name="caret-down-outline"></ion-icon>
										</ion-button>
										<ion-button mode="ios" (click)="this.moveFormBlockFieldDown(card, field)" [ngStyle]="{'visibility': k > 0 ? 'visible' : 'hidden'}" expand="block" fill="outline" style="margin-left: 8px;">
											<ion-icon name="caret-up-outline"></ion-icon>
										</ion-button>
										<ion-button mode="ios" (click)="this.removeFormBlockField(card, field);" expand="block" fill="outline" style="margin-left: 8px;">
											<ion-icon name="trash-bin-outline"></ion-icon>
										</ion-button>
									</ion-row>
								</ion-item>
							}
						}
						@if (this.canEditFormBlock(card)) {
							<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
								<uno-button (click)="this.addFormBlockField(card);">{{'addField' | translate}}</uno-button>
							</div>
						}
					}
					<!-- FormTabCard sorting buttons -->
					@if (card.subTypeUuid === this.subType.uuid) {
						<ion-item class="ion-text-end ion-margin-horizontal ion-no-padding" lines="none" style="height: 40px; margin-bottom: 8px; --inner-padding-end: 0px;">
							<ion-row slot="end">
								<ion-button mode="ios" (click)="this.moveTabCardUp(tab, card)" [ngStyle]="{'visibility': j < tab.cards.length - 1 ? 'visible' : 'hidden'}" expand="block" fill="outline" style="margin-left: 8px;">
									<ion-icon name="caret-down-outline"></ion-icon>
								</ion-button>
								<ion-button mode="ios" (click)="this.moveTabCardDown(tab, card)" [ngStyle]="{'visibility': j > 0 ? 'visible' : 'hidden'}" expand="block" fill="outline" style="margin-left: 8px;">
									<ion-icon name="caret-up-outline"></ion-icon>
								</ion-button>
								<ion-button mode="ios" (click)="this.removeTabCard(tab, card);" expand="block" fill="outline" style="margin-left: 8px;">
									<ion-icon name="trash-bin-outline"></ion-icon>
								</ion-button>
							</ion-row>
						</ion-item>
					}
				}
				<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
					<uno-button (click)="this.addTabCard(tab);">{{'addCard' | translate}}</uno-button>
				</div>
				@if (tab.subTypeUuid === this.subType.uuid) {
					<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
						<uno-button color="error" (click)="this.removeTab(tab);">{{'deleteTab' | translate}}</uno-button>
					</div>
				}
			</uno-tab-section>
		}

		@if (this.history && this.history.length > 0) {
			<uno-tab-section title="{{'history' | translate}}">
				<!-- History -->
				<uno-title>{{'history' | translate}}</uno-title>
				<ion-list class="ion-no-padding ion-no-margin">
					@for (option of this.history; track option; let i = $index) {
						<ion-item (click)="this.openHistoryEntry(this.history[i].id)" button class="ion-margin-horizontal ion-no-padding" lines="full">
							<ion-label>{{option.date | formatDate}}</ion-label>
							<div class="ion-text-end">
								<ion-label>{{option.user.name}}</ion-label>
								<ion-label color="medium" style="font-size: 13px;">{{option.user.email}}</ion-label>
							</div>
						</ion-item>
					}
				</ion-list>
			</uno-tab-section>
		}
	</uno-tab>
</div>
