@if (this.dashboard.widgets.length === 0) {
	<uno-no-data></uno-no-data>
} @else {
	<div style="background-color: var(--gray-12); display: flex; padding: 0 4px 12px 4px;">
		<div class="dashboard-grid" [ngStyle]="{'background-color': this.editable ? 'var(--gray-14)' : 'var(--gray-12)'}">
			@for (widget of this.dashboard.widgets; track widget;) {
				<div class="dashboard-item" [ngClass]="widget.size === 1 ? 'dashboard-item-small' : widget.size === 2 ? 'dashboard-item-medium' : 'dashboard-item-large'">
					<uno-widget [widget]="widget" [editable]="this.editable" (update)="this.updateWidget($event)" (delete)="this.deleteWidget($event)"></uno-widget>
				</div>
			}
		</div>
	</div>
}
