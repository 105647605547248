import {WidgetTypes} from 'src/app/models/dashboards/widget-types';
import {DemoComponentsPage} from 'src/app/modules/demo/demo/components/demo-components.page';
import {DashboardWidget} from 'src/app/models/dashboards/widget';
import {ChartData} from 'chart.js';
import {Modal} from 'src/app/modal';
import {Locale} from 'src/app/locale/locale';
import {WidgetViewTypes} from 'src/app/models/dashboards/widget-view-types';
import {BaseLayout} from '../../widget-layout';

export class WidgetDemo {
	/**
	 * The display type of the widget.
	 */
	public displayType = WidgetTypes.DASHBOARD_WIDGET_TYPE_DEMO_WIDGET;

	/**
	 * The visualization elements available to be used for this widget.
	 */
	public static visualization = [WidgetViewTypes.BAR_CHART, WidgetViewTypes.DOUGHNUT_CHART, WidgetViewTypes.LINE_CHART, WidgetViewTypes.MAP_CHART];

	/**
	 * Permissions needed to view the widget.
	 */
	public permissions = [];

	/**
	 * Fetches the chart data to use on the widget
	 * 
	 * @param widget - The widget to fetch data for.
	 * @returns Chart data to use.
	 */
	public static getData(widget: DashboardWidget): ChartData {
		return DemoComponentsPage.loadDemoData(widget);
	};

	/**
	 * Opens the edit modal for the DEMO widget and returns the updated widget.
	 * 
	 * @param widget - The widget to update.
	 * @returns Updated widget after the modal is closed
	 */
	public static async openEditModal(widgetData: DashboardWidget): Promise<DashboardWidget> {
		await Modal.form(Locale.get('edit'), widgetData, BaseLayout);

		return widgetData;
	}
}
